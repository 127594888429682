import {HttpClient} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {getControlName} from '@shared/validators/utils';
import {CabGetRequest} from '@core/requests/CabRequest';
import {DefaultService} from '@core/services/default.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {BasicFormInputComponent} from '@shared/components/forms/basic/basic-form-input.component';
import {InputLabelComponent} from '@shared/components/forms/components/input-label/input-label.component';
import {
  Component,
  ContentChild,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
  WritableSignal,
} from '@angular/core';
import {AbstractControl, FormsModule, ReactiveFormsModule,} from '@angular/forms';
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, Observable, of, Subject,} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-cab-autocomplete',
  templateUrl: './cab-autocomplete.component.html',
  styleUrls: ['./cab-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    InputLabelComponent,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class CabAutocompleteComponent extends BasicFormInputComponent implements OnChanges {
  @Input({required: true}) _defaultService: DefaultService;
  @Input() width = '100%';
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() clearable = true;
  @Input() bindLabel = 'name';
  @Input() concatAbleLabels: any = {};
  @Input() bindValue = 'id';
  @Input() searchKey = 'search';
  @Input() saveSelectedItemInLocalStorageAs: string;
  @Input() parentControl: AbstractControl | null;
  @Input() parentFilterName: string | null;
  @Input() firstSelect = false;
  @Input() firstLoad = false;
  @Input() notFoundText = this._translateService.instant('Notification.NotFound');
  @Input() params: any = {};
  @Input() multiple = false;
  @Input() appendTo = '';
  @Input() minLengthForSearch = 0;
  @Input() isSearchable: boolean = true;
  @Output() onChangeEmitter = new EventEmitter<any>();

  // @Input() concatItemValues: { keyName: string; concatKeys: string[] } | any = null;
  @Output() onClear = new EventEmitter<any>();
  @ContentChild('label') labelTemplate: TemplateRef<any>;
  loading: WritableSignal<boolean> = signal(false);
  items$: Observable<any>;
  searchInput$: Subject<string> = new Subject<string>();
  protected _request: CabGetRequest;
  private _http = inject(HttpClient);
  private additionalListSubject = new BehaviorSubject<any[]>([]);
  public additionalList$ = this.additionalListSubject.asObservable();

  @Input()
  set additionalList(value: any[]) {
    this.additionalListSubject.next(value);
  }

  ngOnInit() {
    if (this.firstSelect) {
      this.clearable = false;
    }
    super.ngOnInit();
    this._request = new CabGetRequest(this._defaultService.getTableUrl());
    this._request.setDefaultParams({
      ...this._request.defaultParams,
      ...this.params,
      size: 900,
    });

    if (this.parentControl) {
      this.parentFilterName ||= getControlName(this.parentControl);

      this.parentControl.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.clearData();
        });
    }
    // if (this.formControl?.value || this.firstSelect || this.firstLoad) {
    this.loadData();
    // }
    this.onSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.params) {
      this._request?.setDefaultParams({
        ...this._request.defaultParams,
        ...this.params,
        size: 900,
      });

      this.loadData();
    }

  }

  clearData(event?: any) {
    if (this.formControl.value !== null) {
      this.formControl.patchValue(null);
    }
    this.onClear.emit(event);
    this.items$ = of([]);
    this._request.resetParams();
    this.loadData();
  }

  onSearch() {
    if (this.isSearchable) {
      this.searchInput$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map((term) => {
            if (!term || term.length >= this.minLengthForSearch) {
              this._request.putParam(this.searchKey, term);
              this.loadData();
            }
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }

  }

  loadData() {
    if (this.parentControl) {
      if (this.parentControl.value === null) {
        return;
      } else {
        this._request.putParam(
          String(this.parentFilterName),
          this.parentControl.value
        );
      }
    }
    this.loading.set(true);

    this.items$ = combineLatest([
      this.additionalList$,
      of([]).pipe(
        switchMap(() =>
          this._http.get(this._request.getUrl(), {
            params: this._request.getParams(),
          }).pipe(
            map((res: any) => {
              const {data} = res;
              let list = data.data ?? data ?? [];

              if (this.firstSelect && !this.formControl?.value) {
                const first = list[0];
                if (first) {
                  this.formControl.setValue(first[this.bindValue] ?? null);
                  this.onChangeSelect(first);
                }
              }

              if (Object.keys(this.concatAbleLabels).length) {
                list.forEach((item: any) => {
                  item[this.bindLabel] = '';

                  for (const concatAbleLabelsKey in this.concatAbleLabels) {
                    const value = item[this.concatAbleLabels[concatAbleLabelsKey]]
                      ? item[this.concatAbleLabels[concatAbleLabelsKey]]
                      : '-';
                    item[this.bindLabel] += ` ${this._translateService.instant(
                      concatAbleLabelsKey
                    )} : ${value} `;
                  }
                });
              }

              return list;
            }),
            catchError(() => of([])),
            finalize(() => this.loading.set(false))
          )
        )
      )
    ]).pipe(
      map(([additionalList, list]) => [...additionalList, ...list])
    );
  }

  onChangeSelect(item: any) {
    if (this.saveSelectedItemInLocalStorageAs) {
      localStorage.setItem(
        this.saveSelectedItemInLocalStorageAs,
        JSON.stringify(item)
      );
    }

    this.onChangeEmitter.emit(item);
  }

  isReadonly(): boolean {
    if (this.parentControl) {
      return this.parentControl.value === null;
    }
    return this.readonly;
  }

  onFocus() {
    // this.loadData();
  }
}
