import {Injectable} from '@angular/core';
import {ELocalStorage} from '@core/enums/LocalStorage.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  key = 'environment.storageKey';

  public setData(key: ELocalStorage, value: string) {
    localStorage.setItem(key, value);
  }

  public getData(key: ELocalStorage) {
    if (!localStorage.getItem(key)) {
      return null;
    }

    const data: any = localStorage.getItem(key);
    // return this.decrypt(data);
    return data;
  }

  public removeData(key: ELocalStorage) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }
}
