/* eslint-disable */
import {SortModelItem} from 'ag-grid-community';
import {ELocalStorage} from '@core/enums/LocalStorage.enum';
import {removeEmptyProperties} from '@shared/utils/object.utils';
import {LocalService} from '@core/services/local-storage.service';

type ResponseType = 'json' | 'blob';

class CabRequestOptions {
  constructor(
    public params?: any,
    public body?: any,
    public responseType: ResponseType = 'json'
  ) {
  }
}

export class CabRequest {
  regionId: any;
  districtId: any;
  defaultParams: any = {};

  constructor(
    private url: string,
    private method = 'get',
    private options: CabRequestOptions,
    protected _localStorageService = new LocalService()
  ) {
    this.options = {
      params: {},
      body: {},
      responseType: 'json',
    };
    this.updateParams(options.params);
    this.updateBody(options.body);
    this.setDefaultParams(
      removeEmptyProperties({
        regionId: this.regionId,
        districtId: this.districtId,
      })
    );
  }

  setDefaultParams(params: object) {
    this.defaultParams = Object.keys(params).length
      ? removeEmptyProperties(params)
      : params;
  }

  getUrl(): string {
    return this.url;
  }

  updateUrl(newUrl: string): void {
    this.url = newUrl;
  }

  getMethod(): string {
    return this.method;
  }

  updateMethod(newMethod: string): void {
    this.method = newMethod;
  }

  getResponseType(): ResponseType {
    return this.options.responseType;
  }

  getParams() {
    return {...this.options.params, ...this.defaultParams};
  }

  putParam(key: string, value: any) {
    this.options.params[key] = value;
    this.updateParams(this.options.params);
  }

  updateParams(params: object) {
    this.options.params = removeEmptyProperties({
      ...params,
      ...this.defaultParams,
    });
  }

  resetParams(): void {
    this.options.params = {...this.defaultParams};
  }

  getBody() {
    return this.options.body;
  }

  setBody(body: any) {
    this.options.body = body;
  }

  updateBody(body: object, clear = true) {
    if (clear) {
      this.clearBody();
    }
    this.options.body = {
      ...this.options.body,
      ...body,
    };
  }

  clearBody() {
    this.options.body = {};
  }

  setSortParams(sortData: SortModelItem) {
    this.putParam(
      'sort',
      [sortData.colId, sortData.sort.toUpperCase()].join(',')
    );
  }

  removeSortParams() {
    delete this.options.params.sort;
  }
}

export class CabGetRequest extends CabRequest {
  constructor(url: string, params?: object) {
    super(url, 'get', new CabRequestOptions({...params}));
  }
}

export class CabTableRequest extends CabRequest {
  static DEFAULT_PAGE = 0;
  static DEFAULT_SIZE = 15;

  constructor(url: string, params?: object) {
    super(url, 'get', new CabRequestOptions({...params}));
  }

  setPageParams(page: number, size: number) {
    this.putParam('page', page);
    this.putParam('size', size);
  }

  setFilterParams(filter: object) {
    let params = {
      ...this.getParams(),
      ...filter,
    };

    this.updateParams(params);
  }

  removeDefaultQueries(queries: any) {
    const defaults = {
      size: CabTableRequest.DEFAULT_SIZE,
      page: CabTableRequest.DEFAULT_PAGE,
      ...this.defaultParams,
    };
    Object.keys(defaults).forEach((key: string) => {
      if (queries[key] == defaults[key]) {
        delete queries[key];
      }
    });
    return queries;
  }

  setFilterToLocaleStorage(serviceKey: ELocalStorage | any, filterData: any) {
    this._localStorageService.setData(serviceKey, filterData);
  }

  getFilterToLocaleStorage(serviceKey: ELocalStorage | any) {
    return this._localStorageService.getData(serviceKey);
  }
}
