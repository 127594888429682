<div [style.width]="width" class="cab-select {{ customClass }}">
  @if (label.length > 1) {
    <ups-input-label [label]="label" [required]="required"></ups-input-label>
  }
  <ng-select
    (change)="onChangeSelect($event)"
    (clear)="clearData($event)"
    (open)="onFocus()"
    [appendTo]="appendTo"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [clearable]="clearable"
    [formControl]="formControl"
    [id]="id"
    [items]="items$ | async"
    [loading]="loading()"
    [multiple]="multiple"
    [notFoundText]="notFoundText | translate"
    [placeholder]="placeholder | translate"
    [readonly]="isReadonly()"
    [typeahead]="searchInput$"
    class="w-full"
  >
    @if (labelTemplate) {
      <ng-template let-item="item" ng-label-tmp>
        <ng-container [ngTemplateOutletContext]="{ item }" [ngTemplateOutlet]="labelTemplate"/>
      </ng-template>

      <ng-template let-item="item" ng-option-tmp>
        <ng-container [ngTemplateOutletContext]="{ item }" [ngTemplateOutlet]="labelTemplate"/>
      </ng-template>
    }
  </ng-select>

  <!--  @if (showValidationErrors()) {-->
  <!--    <small class="text-danger" [innerHTML]="errorMessagesHtml()"></small>-->
  <!--  }-->

  <div class="error-message label-small-error">
    <span
      *ngIf="showValidationErrors()"
      class="p-error"
    >{{ this.errorMessage() }}</span>
  </div>
</div>
